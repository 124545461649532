<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Registros por página"
          >
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-form-group>
        </b-col>

        <!-- Search -->
        <b-col
          cols="6"
          md="6"
          class="topTable"
        >
          <div class="d-flex align-items-right justify-content-end">
            <b-button
              @click="$router.push({ name: 'add-commission'})">
              Nueva comisión
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>
    <b-overlay
      :show="tableOverlay"
      rounded="sm"
    >
      <div id="main-table">
        <b-table
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="data"
          primary-key="name"
          @row-clicked="onRowClicked"

          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"

          ref="refInvoiceListTable"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No hay registros"
          class="position-relative"
        >

          <!-- Columna: Día de pago -->
          <template #cell(deadlineDay)="data">
            {{ days[data.item.deadlineDay - 1] }}
          </template>

          <!-- Columna: Coste gasto/s -->
          <template #cell(expense)="data">
            <div v-for="(expense, index) in data.item.commissions" :key="index">
              {{ expense.name }}: ${{ formatterNumber(expense.price) }}
            </div>
          </template>

        </b-table>
      </div>
    </b-overlay>
    <br>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >

        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

br {
  user-select: none;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/customHelios.scss';
</style>


<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BFormGroup
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import utils from '../../../utils/utils.js';


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    ToastificationContent,
    vSelect,
    BOverlay,
    BFormGroup,
  },
  data() {
    return {

      // Tabla
      tableColumns: [
        { key: 'name', label: 'Agente', sortable: true, thStyle: {width: '35em'} },
        { key: 'role', label: 'Rol', sortable: true, thStyle: {width: '35em'} },
        { key: 'deadlineDay', label: 'Día de pago', sortable: true, thStyle: {width: '35em'} },
        { key: 'expense', label: 'Coste gasto/s', sortable: false, thStyle: {width: '45em'} },
      ],
      perPage: 5,
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      onFiltered: [],

      // Data
      data: [],

      // Props
      tableOverlay: true,
      days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
    }
  },
  created() {
    this.getCommissionsByAgent();
  },
  methods: {
    getCommissionsByAgent() {
      this.$axios.get(this.$services + 'commissions/get_commissions_by_agent').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.data = res.data.data;
          this.totalRows = res.data.data.length;
        } else
        throw 'Error al obtener comisiones.'
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('danger', String(error));
      }).finally(() => {
        this.tableOverlay = false;
      });
    },
    onRowClicked(item) {
      this.$router.push({ name: 'edit-commission', params: { id: item.idComissionAgent }});
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    formatterNumber(value) {
      return utils.numberWithCommas(value);
    },
  }
}
</script>

